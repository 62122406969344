import React, { FC, useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import ApgLogo from '../../assets/images/logo.svg'
import WildRiftBox from '../../assets/images/wildrift.jpg'
import ValorantBox from '../../assets/images/valorant.jpg'
import TFTBox from '../../assets/images/teamfight-tactics.jpg'
import { LandingStyled } from './Landing.styled';
import { CartStyled } from '../../domain/styles/Card.styled';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { Client, Account, Models, Databases, ID } from 'appwrite';

const { Content } = Layout;

const Landing: FC = () => {
  const [ userLogged, setUser ] = useState<Models.Account<Models.Preferences>>()
  const client = new Client();
  const account = new Account(client);
  const databases = new Databases(client);

  client
    .setEndpoint('http://localhost/v1')
    .setProject('639b3002d1f09a511b3e');

  const onDiscordClick = () => {
    account.createOAuth2Session('discord', 'http://localhost:4200?provider=discord', 'http://localhost:4200')
  };

  const getAccountData = async () => {
    const userLogged = await account.get();
    setUser(userLogged);
  }

  const updateUserData = async () => {
    const documentId = ID.unique();

    if (userLogged?.prefs?.profileId) {
      await databases.updateDocument('639b313cda3ce233dcb4', '639b3148737f08e82c27', userLogged?.prefs.profileId, {
        userId: userLogged?.$id,
        email: userLogged?.email,
        avatar: 'https://pbs.twimg.com/profile_images/1378918909793996801/zutMgt4H_400x400.jpg',
        provider: 'discord'
      }); 
    } else {
      const profileUser = await databases.createDocument('639b313cda3ce233dcb4', '639b3148737f08e82c27', documentId, {
        userId: userLogged?.$id,
        email: userLogged?.email,
        avatar: 'https://pbs.twimg.com/profile_images/1378918909793996801/zutMgt4H_400x400.jpg',
        provider: 'discord'
      }); 

      await account.updatePrefs({ 
        provider: 'discord',
        profileId: profileUser.$id
      });
    }
  }

  useEffect(() => {
    getAccountData();
  }, [])

  useEffect(() => {
    if (userLogged?.status) {
      updateUserData()
    }
  }, [userLogged])
  

  return (
    <>
      <Section spacing={'md'}>
        <Content className="container text-center">
          <LandingStyled.HeroImage>
            <img src={ApgLogo} />
          </LandingStyled.HeroImage>

          <LandingStyled.TitleLanding italic level={1}>
            ¿Estás listo para <span>unirte al club</span>?
          </LandingStyled.TitleLanding>

          <LandingStyled.TitleLanding italic level={3}>
            Bienvenidos a la primera plataforma de estadísticas de esports en LATAM, donde verás las <span>estadísticas competitivas</span> de los juegos mas valorados por la comunidad. 
          </LandingStyled.TitleLanding>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <CartStyled.LinkBox href='https://wildrift.apgstats.club'>
                <img src={WildRiftBox} />
              </CartStyled.LinkBox>
              <LandingStyled.TitleLanding level={5} onClick={onDiscordClick}>Ir al RiftClub</LandingStyled.TitleLanding>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <CartStyled.LinkBox>
                <img src={ValorantBox} className="disabled" />
              </CartStyled.LinkBox>
              <LandingStyled.TitleLanding level={5}>Próximamente</LandingStyled.TitleLanding>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <CartStyled.LinkBox>
                <img src={TFTBox} className="disabled" />
              </CartStyled.LinkBox>
              <LandingStyled.TitleLanding level={5}>Próximamente</LandingStyled.TitleLanding>
            </Col>
          </Row>
        </Content>
      </Section>
      <ApgFooter copyright={'© 2019-2022'} />
    </>
  )
};

export default Landing;
