import React, { useState, useEffect } from 'react';

// Interfaz para definir el tipo de dato de cada seguidor
interface Follower {
  name: string;
  description: string;
  location: string;
  profile_image_url: string;
  statuses_count: number;
  last_tweet_at: string;
  created_at: string;
  followers_count: string;
}

const TwitterFollowers: React.FC = () => {
  // Estado para almacenar la lista de seguidores
  const [followers, setFollowers] = useState<Follower[]>([]);

  // Función para obtener la lista de seguidores
  const getFollowers = async () => {
    // Nombre de usuario del cual quieres obtener la lista de seguidores
    const screenName = "ddumst";

    // Bucle para obtener todas las páginas de resultados
    let cursor = -1;
    while (cursor !== 0) {
      // Realiza la solicitud para obtener la lista de seguidores
      const response = await fetch(`https://api.twitter.com/1.1/followers/list.json?screen_name=${screenName}&cursor=${cursor}`, {
        headers: {
          // Reemplaza estos valores con tus claves de acceso y tokens de autenticación
          "Authorization": "AAAAAAAAAAAAAAAAAAAAAD8KKQEAAAAA%2B37d2EOf8ie%2BmicKIJV%2FA8LSbuU%3DIvaO3k7XRV2gdonUYufOvApkzhGL3hcoJM6NyYDnlagqSUvYIt"
        }
      });
      const data = await response.json();

      // Recorre la lista de seguidores y guarda la información básica de cada uno en el array
      for (const user of data.users) {
        setFollowers(prevFollowers => [...prevFollowers, {
          "name": user.name,
          "description": user.description,
          "location": user.location,
          "profile_image_url": user.profile_image_url,
          "statuses_count": user.statuses_count,
          "last_tweet_at": user.status?.created_at,
          "created_at": user.created_at,
          "followers_count": user.followers_count
        }]);
      }

      // Actualiza el cursor para obtener la siguiente página de resultados
      cursor = data.next_cursor;
    }
  };

  // Llamar a la función para obtener la lista de seguidores al iniciar la aplicación
  useEffect(() => {
    getFollowers();
  }, []);

  return (
    <div className="container">
      <h1>Seguidores de Twitter</h1>
      <table>
        <thead>
          <tr>
            <th>Imagen de perfil</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Ubicación</th>
            <th>Número de tweets</th>
            <th>Número de followers</th>
            <th>Último tweet</th>
            <th>Fecha de creación de la cuenta</th>
          </tr>
        </thead>
        <tbody>
          {followers.map(follower => (
            <tr key={follower.name}>
              <td><img src={follower.profile_image_url} alt={follower.name} /></td>
              <td>{follower.name}</td>
              <td>{follower.description}</td>
              <td>{follower.location}</td>
              <td>{follower.statuses_count}</td>
              <td>{follower.followers_count}</td>
              <td>{follower.last_tweet_at}</td>
              <td>{follower.created_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );  
}

export default TwitterFollowers