import styled from "styled-components";
import { MediaQuery, Palette } from "@apg-stats/apg-core/lib/css-in-js";
import Title from "antd/lib/typography/Title";

const HeroBanner = styled.div`
  background-size: cover;
  background-position: center center;
  border-bottom: 2px solid ${Palette.DarkBlue600};
  display: flex;
  justify-content: center;
  margin: 4rem 0 0;
  position: relative;

  &:before {
    background-color: ${Palette.Opacity50};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

const HeroImage = styled.div`
  padding-bottom: 2rem;
  padding-top: 2rem;
  z-index: 2;

  img {
    width: 30rem;

    ${MediaQuery.LessThanMedium} {
      width: 90%;
    }
  }
`

const TitleLanding = styled(Title)`
  color: ${Palette.Gray100} !important;
  line-height: 1.5 !important;
  margin: 0 auto;
  max-width: 50rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  span {
    background-color: #00a2ee;
    border-radius: 0.625rem;
    padding: 0.40rem;
  }
`

export const LandingStyled = {
  HeroBanner,
  HeroImage,
  TitleLanding
}