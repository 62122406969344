import { Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import styled from "styled-components";

interface ContainerInterface {
  noMinHeight?: boolean;
  padding?: string | number;
}

const LinkBox = styled.a<ContainerInterface>`
  background-color: ${Palette.Gray200};
  border-radius: 0 1rem 0 1rem;
  display: block;
  margin-bottom: ${Spacing.Spacing2};
  overflow: hidden;

  ${({ noMinHeight }) => noMinHeight && `
    min-height: 0 !important;
  `}

  &:hover {
    img {
      transform: scale(1.15) rotate(3deg);
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    transition: ease-in-out 300ms;

    &.disabled {
      filter: grayscale(1);
    }
  }
`

const Container = styled.div<ContainerInterface>`
  background-color: ${Palette.Gray200};
  border-radius: 0 1rem 0 1rem;
  margin-bottom: ${Spacing.Spacing2};
  overflow: hidden;

  ${({ noMinHeight }) => noMinHeight && `
    min-height: 0 !important;
  `}

  img {
    object-fit: cover;
    width: 100%;

    &.disabled {
      filter: grayscale(1);
    }
  }
`

export const CartStyled = {
  Container,
  LinkBox
}