import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Layout from 'antd/lib/layout';

import './App.scss';

import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import { init } from './utils/ga';
import Landing from './app/landing/Landing';
import TwitterFollowers from './app/landing/FetchingExample';

const App: FC = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    init("G-2MJLBFQDVL");
    setIsReady(true)
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Layout style={{ backgroundColor: Palette.Gray1000, color: Palette.Gray100 }}>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/followers">
            <TwitterFollowers />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    </>
  )
};

export default App;
