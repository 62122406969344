import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { Spin } from 'antd';
import App from './App';
import './index.scss';
import { GlobalAppProvider } from './store/app-context';

const LoadingScreen = (): React.ReactElement => {
  return (
    <div className="loading-container">
      <Spin />
    </div>
  )
}

export function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Suspense fallback={<LoadingScreen />}>
        <CookiesProvider>
          <GlobalAppProvider>
            <ScrollToTop />
            <App />
          </GlobalAppProvider>
        </CookiesProvider>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();